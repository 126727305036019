@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-recruitment-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .hot-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
  }
  .hot-tab {
    margin: 20px 0;
  }
  .trend-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
  }
  .trend-tab {
    margin: 20px 0;
  }
  .dispach-warp {
    padding-top: 60px;
    position: relative;
    .position-con {
      position: absolute;
      width: 76px;
      top: 0;
      left: 0;
      text-align: center;
      margin-left: -38px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .green {
        @include success-bg(0.2);
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        span {
          @include mont-font();
          font-size: 18px;
          color: $success;
        }
        i {
          @include mont-font();
          font-size: 14px;
          color: $success;
        }
      }

      .orange {
        @include secondary-bg(0.2);
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        span {
          font-size: 18px;
          color: $secondary;
          @include mont-font();
        }
        i {
          @include mont-font();
          font-size: 14px;
          color: $secondary;
        }
      }

      .red {
        @include error-bg(0.2);
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        span {
          @include mont-font();
          font-size: 18px;
          color: $error;
        }
        i {
          @include mont-font();
          font-size: 14px;
          color: $error;
        }
      }
      b {
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-width: 6px 8px 16px 8px;
        background-color: white;
      }
    }
    .pic {
      display: flex;
      justify-content: space-between;
      span {
        display: block;
        width: 32.5%;
        height: 5px;
        border-radius: 2px;
        &:nth-child(1) {
          background-color: $success;
        }
        &:nth-child(2) {
          background-color: $secondary;
        }
        &:nth-child(3) {
          background-color: $error;
        }
      }
    }
    .mid {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      span {
        color: $c55;
        font-size: 12px;
        display: block;
        width: 33.3333%;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      span {
        color: $cf;
        font-size: 12px;
        display: block;
        width: 33.3333%;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
  }
}
