@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';
@import '@scss/media.scss';

.header-comp {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  .header-container {
    width: 100%;
    min-width: 1280px;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;

    .logo-15 {
      font-size: 28px;
      cursor: pointer;
    }

    .header-menu {
      flex: 1;
      height: 100%;
      display: flex;
      padding-left: 70px;

      .menu-100 {
        display: inline-flex;
        height: 100%;
      }
    }

    .header-left {
      display: inline-flex;

      img {
        width: 100px;
        cursor: pointer;
      }
    }
    .header-right {
      display: inline-flex;
      height: 100%;

      .right-90 {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        .line-910 {
          height: 100%;
          display: inline-flex;
          align-items: center;
        }
        .line-1110 {
          display: inline-flex;
          height: 12px;
          width: 2px;
          background-color: $ec;
        }
      }
      .message-30, .user-30 {
        position: relative;
        width: 60px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .message-11 { font-size: 22px; }
        .white-55 { color: $fff; }
        .black-55 { color: $c55; }
        sup {
          @include white-color(1);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 15px;
          right: 10px;
          height: 12px;
          padding: 0 3px;
          border-radius: 4px;
          line-height: 1;
          background-color: $t7Four;
        }
      }
    }
  }
}

.opacity-header { @include white-bg(0.1); }

.primary-header { @include primary-bg(1); }

.white-header { @include white-bg(1); }

.user-pop_50 {
  display: flex;
  position: fixed;
  // top: 59px;
  left: 0;
  width: 100%;
  justify-content: center;

  .user-pop_51 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1920px;
    min-width: 1280px;
    padding-right: 82px;
  }

  .user-pop_015 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // position: absolute;
    width: 320px;
    // top: 60px;
    z-index: 19;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);

    .user-016 {
      display: flex;
      flex-direction: column;

      .user-017 {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 10px 20px 0 20px;
        cursor: pointer;

        .logo-0020 {
          @include primary-color(1);
          font-size: 30px;
          cursor: pointer;
        }
        p.line-018 {
          display: inline-flex;
          flex-direction: column;
          margin-left: 12px;

          b {
            line-height: 1;
            color: $c333;
          }
          span {
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 1;
            margin-top: 8px;
            color: $c999;
          }
        }
      }

      .line-017 {
        display: inline-flex;
        justify-content: flex-end;
        margin: 10px 0;
      }

      .service-017 {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        & > b {
          display: inline-flex;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          margin: 10px 0 0 0;
          color: $c333;
        }

        .service-018 {
          display: flex;
          align-items: center;
          padding: 10px 10px 0 10px;

          .logo-0010 {
            display: inline-flex;
            margin-right: 20px;
            i {
              font-size: 54px;
              color: $c999;
            }
            img { width: 60px; }
          }
          .contact-0010 {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;

            img {
              width: 60px;
              height: 60px;
              margin-right: 24px;
              border-radius: 50%;
            }
          }
          .text11 {
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 2;
            margin-top: 10px;
            color: $c666;
          }
        }
        .line-019 {
          width: 100%;
          display: inline-flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}
