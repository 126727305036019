@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.footer-comp {
  display: flex;
  justify-content: center;
  height: 450px;
  width: 100%;
  background-color: #1E1E1E;
  margin-top: 20px;

  .footer-container {
    width: 100%;
    min-width: 1280px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    padding: 50px 82px 0 82px;

    .logo-11 {
      @include white-color(1);
      font-size: 30px;
      margin-bottom: 40px;
    }
    .hidden { visibility: hidden; }

    .footer-01 {
      flex: 1;
      display: flex;
      flex-direction: column;

      .section-15 {
        display: flex;
        justify-content: space-between;

        .line-51 {
          display: inline-flex;
          flex-direction: column;
          b {
            @include white-color(1);
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 30px;
          }
          span {
            @include white-color(0.5);
            font-size: 12px;
            line-height: 1;
            margin-bottom: 14px;
          }
          .try-55 {
            display: inline-flex;
            margin-top: 20px;
          }
          .line-55 {
            display: inline-flex;

            .line-505 {
              display: inline-flex;
              flex-direction: column;
              margin-left: 10px;
              margin-bottom: 10px;

              span {
                line-height: 1;
                margin-bottom: 10px;
              }
            }
            .icon-10 {
              @include white-color(0.5);
              font-size: 20px;
            }
          }
        }
      }
    }

    .footer-02 {
      display: flex;
      margin: 15px 0;

      .line-1005 {
        @include white-color(0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        p {
          font-size: 12px;
          line-height: 1.5;

          span {
            cursor: pointer;
          }
        }
      }
      .line-1006 {
        @include white-color(1);
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        line-height: 1;
      }
    }

    .footer-03 {
      display: flex;
      align-items: center;
      height: 34px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      span {
        @include white-color(0.3);
        font-size: 12px;
        line-height: 1;
        margin-right: 50px;
      }
    }
    .link-10 { cursor: pointer; }
  }
}
