@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.role-recruitment-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .role-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .role-content {
      .role-content-table {
        height: 370px;
        overflow-y: auto;
      }
      .table {
        display: flex;
        justify-content: space-between;
        .right {
          width: 350px;
          margin-left: 10px;
          background-color: $fff;
          border-radius: 8px;
          padding: 10px;
        }
        .role-svg-warp {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .items {
            width: calc(50% - 5px);
            padding: 10px 35px 20px;
            background-color: $white;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .svg-title {
              font-size: 18px;
              font-weight: 600;
              color: $black;
              text-align: center;
              height: 27px;
            }
            .svg-content {
              background-color: $f7;
              // display: flex;
              // justify-content: flex-start;
              // flex-wrap: wrap;
              border-radius: 5px;
              margin-top: 20px;
              padding-bottom: 10px;
              flex: 1;
              span{
                background-color: $fff;
                margin: 10px 8px 0;
                padding: 5px 10px;
                border-radius: 5px;
                border: 1px solid $ec;
                cursor: pointer;
                font-size: 12px;
                height: 30px;
                display: inline-block;
              }
            }
            .role-svg {
              height: 300px;
              width: 100%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .role-tab {
    margin: 20px 0;
  }
}
