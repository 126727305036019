@import "./color.scss";
@import "./mixin.scss";

$minWidth: 1280;
$maxWidth: 1920;

.hidden { visibility: hidden; }
.visible { visibility: visible; }
.none { display: none !important; }

.pointer { cursor: pointer; }

// 数字字体
.digit-font {
  font-family: "Roboto", system-ui;
}
.mont-font {
  font-family: "Roboto", system-ui;
}

.pop-section {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 6px 10px;

  button {
    margin-right: 10px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
}

.picker-public-head {
  border-bottom: 1px solid $f1;
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  .title {
    @include black-color(0.85);
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding-top: 4px;
    border-top: 2px solid $secondary;
    margin-top: 10px;
  }
  svg {
    margin-right: 20px;
    cursor: pointer;
  }
}

// 城市选择器
.city-picker_051051 {
  display: flex;
  width: 440px;
  height: 260px;

  .sider-051 {
    width: 25%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  .city-051 {
    width: 75%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow: auto;
  }
  .type-051 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// 通用选择器
// 城市选择器
.universal-picker_051051 {
  display: flex;
  width: 424px;
  height: 244px;
  padding: 8px;
  .universal-051 {
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    // overflow: auto;
    .universal-picker-change {
      display: block;
      width: auto;
      // height: 20px;
      padding: 8px 20px;
      float: left;
      cursor: pointer;
      &:hover {
        @include primary-bg(0.04);
        color: $primary;
      }
    }
    .universal-active {
      color: $primary;
      display: block;
      width: auto;
      // height: 20px;
      padding: 8px 20px;
      float: left;
      cursor: pointer;
      &:hover {
        @include primary-bg(0.04);
        color: $primary;
      }
    }
  }
  .type-051 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// 时间选择器
.date-picker_051051 {
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 313px;

  p.picker-title {
    height: 44px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .picker-year_051 {
    flex: 1;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .picker-quarter_051 {
    display: flex;
    flex: 1;
  }
  .quarter-052 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    width: 33%;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  .quarter-053 {
    flex: 1;
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

// 高亮背景模糊效果
.blur_005 {
  position: absolute;
  left: 0;
  height: 32px;
  bottom: -18px;
  background: linear-gradient(180deg, rgba(52, 63, 153, 0) 0%, $primary 100%);
  opacity: 0.75;
  filter: blur(7px);
  z-index: -1;
}

.dialog-010 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  height: 408px;

  .top-010 {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .title-010 {
      display: inline-flex;

      span {
        padding-top: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        border-top: 2px solid $secondary;
      }
    }
    .line01 {
      margin-top: 15px;
    }
  }

  .bottom-010 {
    display: inline-flex;
    padding: 8px 20px;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
}

.fixed-top {
  @include black-color(0.65);
  @include white-bg(1);
  position: fixed;
  top: 6px;
  left: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12px;
  line-height: 1;
  border-radius: 8px;
  z-index: 109;
}

.alert-050 {
  @extend .fixed-top;
  width: 250px;
  margin-left: -125px;
}
.tip-10 {
  @extend .fixed-top;
  width: 140px;
  margin-left: -70px;
}

.picker-010 {
  /* stylelint-disable-next-line */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2) !important;
}

.max-1011 {
  position: relative;
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;

  .max-101 {
    @include white-bg(1);
    position: relative;
    width: 520px;
    height: 215px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    border-radius: 4px;

    .top-101 {
      display: flex;
      flex-direction: column;
      padding: 0 30px 50px 30px;

      .title-101 {
        display: inline-flex;

        span {
          font-size: 16px;
          padding-top: 4px;
          font-weight: 600;
          line-height: 1;
          border-top: 2px solid $secondary;
          color: $c333;
        }
      }
      .desc-101 {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        color: $c333;
      }
    }
    .bottom-101 {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      flex: 1;
      padding: 0 20px;
      border-top: 1px solid $f1;
    }
    .close-101 {
      width: 40px;
      height: 40px;
      position: absolute;
      right: -40px;
      top: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.pop-1012 {
  width: 96px;
  height: 96px;
}

.tooltip-10 {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 128px;

  & > span {
    @include black-color(0.85);
    font-weight: 600;
    margin-bottom: 10px;
  }
  .p1, .p2, .p3 {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: inline-flex;
      align-items: center;
    }
    i {
      display: inline-flex;
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
    span {
      @include black-color(0.65);
      font-size: 12px;
      line-height: 1;
      margin-left: 8px;
    }
    b {
      @include mont-font();
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
    }
  }
  .p1 {
    i { @include primary-bg(1); }
  }
  .p2 {
    i { background-color: $t3Two; }
  }
  .p3 {
    i { @include secondary-bg(2); }
  }
}

// 分页样式
.pagi-10 {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.pop-50 {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 10px;
}

.empty-101 {
  @include white-bg(1);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border-radius: 8px;

  img {
    width: 185px;
    height: 100px;
  }
  span {
    @include black-color(0.5);
    margin-top: 8px;
    font-size: 12px;
  }
}
.empty-1016 {
  margin: 12px 0 0 0;
  padding: 40px 0;
}
.empty-1017 {
  height: 200px;
  margin: 8px 0 0 0;
}

.popper-201 {
  @include black-bg(0.79);
  @include white-color(1);
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 16px;
  border-radius: 4px;
  font-size: 12px;
}

.info-icon_01 {
  @include black-bg(0);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
}

.industry-modal_055 {
  position: relative;
  width: 560px;
  overflow: hidden;

  .modal-056 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    width: 520px;
    height: 360px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .title-057 {
      display: inline-flex;
      align-items: center;
      height: 62px;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);

      span {
        padding-top: 6px;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        border-top: 2px solid $secondary;
        color: $c333;
      }
    }

    .content-058 {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 8px 0;

      p {
        display: inline-flex;
        align-items: center;
        padding: 11px 20px;
        cursor: pointer;

        b, span {
          flex: 1;
          line-height: 14px;
        }
        b {
          @include black-color(0.85);
          font-size: 14px;
          font-weight: 400;
        }
        span {
          @include black-color(0.5);
          font-size: 12px;
          font-weight: 400;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);

          b { font-weight: 500; }
        }
      }
    }
  }
  .close-line {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
  }
}

.date-picker_105 {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 315px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  .line-111 {
    width: 100%;
    display: inline-flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .year-10 {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .quarter-10 {
    display: flex;
    flex: 1;
    overflow: hidden;

    .left-11 {
      width: 115px;
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      overflow-y: auto;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
    .right-12 {
      flex: 1;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      overflow-y: auto;
    }
  }
}

.tip-0555 {
  display: inline-flex;
  flex-direction: column;
  width: 180px;
  height: 96px;

  b {
    @include black-color(0.85);
    font-weight: 600;
    margin-bottom: 16px;
  }
  p {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    strong {
      @include black-color(0.65);
      font-size: 12px;
      line-height: 1;
    }
    span.span01 {
      display: inline-flex;
      align-items: center;
    }
    span.span02 {
      @include black-color(0.85);
      @include mont-font();
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;

      i {
        font-size: 12px;
        font-weight: 600;
      }
    }
    span.span03 {
      @include black-color(0.85);
      @include mont-font();
    }
    em {
      display: inline-flex;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .em-01 { @include primary-bg(1); }
    .em-02 { background-color: $t3Two; }
    .em-03 { @include secondary-bg(1); }
    .em-011 { border-radius: 2px; }
    .em-012 { border-radius: 2px; }
  }
  .up-022 {
    margin-bottom: 10px;
  }
}

.tip-0556 { height: 136px; }
.tip-0557 { height: 183px; }


.tip-0501 {
  display: inline-flex;
  flex-direction: column;
  width: 180px;
  height: 96px;

  b {
    @include black-color(0.85);
    font-weight: 600;
    margin-bottom: 16px;
  }
  p {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    strong {
      @include black-color(0.65);
      font-size: 12px;
      line-height: 1;
    }
    span.span01 {
      display: inline-flex;
      align-items: center;
    }
    span.span02 {
      @include black-color(0.85);
      @include mont-font();
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;

      i {
        font-size: 12px;
        font-weight: 600;
      }
    }
    span.mont-1010 {
      @include mont-font();
      font-size: 14px;
    }
    em {
      display: inline-flex;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .em-01 {
      @include primary-bg(1);
    }
    .em-02 {
      @include secondary-bg(1);
    }
  }
  .up-022 {
    margin-bottom: 10px;
  }
  .up-top-10 { margin-top: 10px; }
}

.tip-05010 {
  .em-lightgray { background-color: $cf; }
  .em-secondary { @include secondary-bg(1); }
  .em-primary { @include primary-bg(1); }
}
.tip-height-110 { height: 110px; }
.tip-height-70 { height: 66px; }
.tip-height-130 { height: 130px; }
.export-table {
  width: 80px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: $primary;
  color: $fff;
  justify-content: center;
  border-radius: 5px;
  margin-top: 30px;
}
