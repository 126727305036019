@import "./color.scss";

@mixin min-width() { min-width: 1280px; }
@mixin max-width() { max-width: 1920px; }

@mixin primary-color($opacity) { color: rgba(52, 63, 153, $opacity); }
@mixin primary-bg($opacity) { background-color: rgba(52, 63, 153, $opacity); }

@mixin secondary-color($opacity) { color: rgba(248, 171, 66, $opacity); }
@mixin secondary-bg($opacity) { background-color: rgba(248, 171, 66, $opacity); }

@mixin success-color($opacity) { color: rgba(0, 195, 137, $opacity); }
@mixin success-bg($opacity) { background-color: rgba(0, 195, 137, $opacity); }

@mixin error-color($opacity) { color: rgba(224, 32, 32, $opacity); }
@mixin error-bg($opacity) { background-color: rgba(224, 32, 32, $opacity); }

@mixin black-color($opacity) { color: rgba(0, 0, 0, $opacity); }
@mixin black-bg($opacity) { background-color: rgba(0, 0, 0, $opacity); }

@mixin white-color($opacity) { color: rgba(255, 255, 255, $opacity); }
@mixin white-bg($opacity) { background-color: rgba(255, 255, 255, $opacity); }

@mixin dark-color($opacity) { color: rgba(75, 83, 109, $opacity); }
@mixin dark-bg($opacity) { background-color: rgba(75, 83, 109, $opacity); }

@mixin dark-boder($opacity) { border-left: rgba(0, 0, 0, $opacity); }

@mixin dark-border($opacity) { border: 1px rgba(0, 0, 0, $opacity) solid; }

@mixin bottom-boder($opacity) { border-bottom: rgba(0, 0, 0, $opacity) 1px solid; }

@mixin primary-bg($opacity) { background-color: rgba(52, 63, 153, $opacity); }

@mixin white-bottom-boder($opacity) { border-bottom: rgba(255, 255, 255, $opacity) 1px solid; }

@mixin c53-bg($opacity) { background-color: rgba(51, 53, 63, $opacity); }

@mixin c33-bg($opacity) { background-color: rgba(51, 51, 51, $opacity); }


// 数字字体
@mixin digit-font() { font-family: "Roboto", system-ui; }
@mixin mont-font() { font-family: "Roboto", system-ui; }
@mixin pingfang-font() { font-family: "PingFangSC Regular", system-ui; }

@mixin gold-color($opacity) { color: rgba(248, 171, 66, $opacity); }
@mixin silver-color($opacity) { color: rgba(153, 153, 153, $opacity); }
@mixin copper-color($opacity) { color: rgba(213, 85, 0, $opacity); }
@mixin other-color($opacity) { color: rgba(204, 204, 204, $opacity); }
@mixin c55-color($opacity) { color: rgba(72, 82, 89, $opacity); }

// grid & flex
@mixin flex { display: flex; }
@mixin inline-flex { display: inline-flex; }
@mixin grid { display: grid; }
@mixin inline-grid { display: inline-grid; }

@mixin column { flex-direction: column; }
@mixin align-center { align-items: center; }
@mixin justify-center { justify-content: center; }

@mixin flex-column {
  @include flex;
  @include column;
}
@mixin inline-flex-column {
  @include inline-flex;
  @include column;
}

@mixin flex-center {
  @include flex;
  @include align-center;
  @include justify-center;
}

@mixin inline-flex-center {
  @include inline-flex;
  @include align-center;
  @include justify-center;
}

// color list
@mixin color01($opacity) { color: rgba(52, 63, 153, $opacity); }
@mixin color01-bg($opacity) { background-color: rgba(52, 63, 153, $opacity); }
@mixin color02($opacity) { color: rgba(248, 171, 66, $opacity); }
@mixin color02-bg($opacity) { background-color: rgba(248, 171, 66, $opacity); }
@mixin color03($opacity) { color: rgba(134, 212, 224, $opacity); }
@mixin color03-bg($opacity) { background-color: rgba(134, 212, 224, $opacity); }
@mixin color04($opacity) { color: rgba(250, 100, 1, $opacity); }
@mixin color04-bg($opacity) { background-color: rgba(250, 100, 1, $opacity); }
@mixin color05($opacity) { color: rgba(157, 162, 176, $opacity); }
@mixin color05-bg($opacity) { background-color: rgba(157, 162, 176, $opacity); }
@mixin color06($opacity) { color: rgba(133, 218, 50, $opacity); }
@mixin color06-bg($opacity) { background-color: rgba(133, 218, 50, $opacity); }
@mixin color07($opacity) { color: rgba(73, 121, 215, $opacity); }
@mixin color07-bg($opacity) { background-color: rgba(73, 121, 215, $opacity); }
@mixin color08($opacity) { color: rgba(112, 32, 130, $opacity); }
@mixin color08-bg($opacity) { background-color: rgba(112, 32, 130, $opacity); }
@mixin color09($opacity) { color: rgba(182, 100, 210, $opacity); }
@mixin color09-bg($opacity) { background-color: rgba(182, 100, 210, $opacity); }
@mixin color10($opacity) { color: rgba(0, 195, 137, $opacity); }
@mixin color10-bg($opacity) { background-color: rgba(0, 195, 137, $opacity); }
@mixin color11($opacity) { color: rgba(255, 82, 82, $opacity); }
@mixin color11-bg($opacity) { background-color: rgba(255, 82, 82, $opacity); }
@mixin color12($opacity) { color: rgba(204, 204, 204, $opacity); }
@mixin color12-bg($opacity) { background-color: rgba(204, 204, 204, $opacity); }
