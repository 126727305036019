@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.meal-service {
  display: flex;
  justify-content: space-between;

  .meal-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    margin-top: 42px;
    padding-right: 20px;

    &:last-child { padding-right: 0; }
    .p-50 {
      position: relative;
      display: flex;
      flex-direction: column;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 160px;
        z-index: 10;
      }
      span {
        @include white-color(1);
        display: inline-grid;
        width: 100%;
        height: 100px;
        align-items: center;
        justify-content: center;
        z-index: 20;
        font-size: 36px;
        font-weight: 600;
        line-height: 1;
      }
      .span-50 {
        @include white-color(1);
        font-size: 22px;
        align-items: center;
        justify-content: center;
        display: inline-grid;
        width: 100%;
        height: 60px;
        line-height: 1;
      }
    }

    .card-60 {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $cf;
      border-left: 1px solid $cf;
      border-bottom: 1px solid $cf;
      border-radius: 0 0 20px 20px;

      .price-100 {
        width: 100%;
        display: none;
        justify-content: center;
        padding-top: 40px;
        margin-bottom: 30px;
        b {
          @include black-color(1);
          position: relative;
           @include mont-font();
          top: 10px;
          font-size: 28px;
          font-weight: 600;
          line-height: 1;
        }
        strong {
          @include black-color(1);
           @include mont-font();
          margin: 0 4px;
          font-size: 44px;
          font-weight: 600;
          line-height: 1;
        }
        span {
          @include black-color(1);
          position: relative;
          top: 14px;
          font-size: 16px;
          line-height: 1;
          font-weight: 400;
        }
      }
      .apply-10 {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 60px;
      }
      .lit-10 {
        display: inline-flex;
        align-items: center;
        width: 100%;
        padding: 0 20px;

        i {
          margin: 0 8px;
          font-size: 12px;
          line-height: 1;
          font-weight: 400;
          color: $c87;
        }
        span {
          display: inline-flex;
          flex: 1;
          height: 2px;
          border-bottom: 1px dashed $cf;
        }
      }
      .lit-11 {
        i { color: $e2; }
        span { border-bottom: 1px dashed $e2; }
      }
      .tag-10 {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 0 10px 0;

        span {
          @include secondary-bg(0.2);
          @include secondary-color(1);
          display: inline-flex;
          align-items: center;
          margin: 10px 5px 0 5px;
          padding: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 1;
        }
      }
      .tag-11 {
        padding: 0 12px;
        span {
          line-height: 1.5;
        }
      }
      .list-11 {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        padding: 40px 20px;
        height: 620px;

        p {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          &:first-child { margin-top: 0; }
          span {
            line-height: 1;
            color: $c87;
            font-size: 12px;
          }
          i {
            line-height: 1;
            font-size: 14px;
            visibility: hidden;
            color: $c34;
          }
        }
        p.light-11 {
          span { @include black-color(1); }
          i { visibility: visible; }
        }
      }
      .list-12 {
        p {
          span { @include black-color(1); }
          b {
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            color: $e2;
          }
        }
      }
    }
  }
}
