@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.quertionIconWarp {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 1002;
  .quertionWarp {
    position: relative;
  }
  .quertionIcon {
    width: 60px;
    height: 60px;
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    color: $black;
    padding: 5px 0;
    cursor: pointer;
  }
  .quertionModel {
    position: absolute;
    bottom: 65px;
    right: 10px;
    border-radius: 10px;
    width: 520px;
    background: $white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 0px;
    .oneTittle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: $black;
      font-weight: 600;
      padding: 0 20px;
      b {
        cursor: pointer;
      }
    }
    .twoTittle {
      font-size: 12px;
      color: $c87;
      padding: 10px 20px;
      border-bottom: 1px solid $ec;
    }
    .answer {
      padding: 30px 15px 10px;
    }
  }
}
