@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.hot-content {
  .table {
    display: flex;
    justify-content: space-between;
    .right {
      width: 350px;
      margin-left: 10px;
      background-color: $fff;
      border-radius: 8px;
      padding: 10px;
      p {
        line-height: 25px;
        font-size: 14px;
        color: $c55;
        &:nth-child(1) {
          line-height: auto;
          font-weight: 600;
        }
        span {
          @include mont-font();
          font-size: 14px;
        }
      }
    }
    .industry-hot-warp-over {
      width: calc(100% - 360px);
      border-radius: 8px;
      background-color: $fff;
      padding: 15px;
      .industry-hot-warp {
        overflow-x: auto;
        .pic-title {
          font-size: 12px;
          color: $c87;
          margin-bottom: 10px;
          span {
            @include mont-font();
            font-size: 20px;
            color: $secondary;
          }
        }
        .industry-hot-content {
          display: flex;
          justify-content: space-between;
          height: 350px;
          padding-bottom: 10px;
          .output-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            &:hover {
              .tooptip {
                display: block;
              }
            }
            .tooptip {
              display: none;
              width: 180px;
              position: absolute;
              left: 0px;
              top: 30%;
              z-index: 1;
              background: $white;
              padding: 10px;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              p {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                span {
                  display: flex;
                  width: 120px;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  i {
                    width: 8px;
                    height: 8px;
                    display: block;
                    border-radius: 8px;
                    margin-right: 5px;
                  }
                }
                strong {
                  @include mont-font();
                  font-size: 14px;
                }
                &:nth-child(1) {
                  font-size: 14px;
                  font-weight: 600;
                  margin-bottom: 16px;
                }
              }
            }
            .show-bar {
              flex: 1;
              position: relative;
              margin: 5px 0;
              width: 32px;
              height: 100%;
              .show-number {
                // position: absolute;
                width: 32px;
                // bottom: 0;
                // left: 0;
                font-weight: 600;
                color: $secondary;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: center;
                height: calc(100% - 5px);
                i {
                  @include mont-font();
                }
                b {
                  display: block;
                  width: 32px;
                  background: linear-gradient(180deg, $fad9 0%, $f76b 100%);
                  border-radius: 4px 4px 0 0;
                  margin-top: 5px;
                }
                .changeColor {
                  background: linear-gradient(180deg, $d6fa 0%, $c389 100%);
                }
              }
              .picwarps {
                background-color: $f76b;
                height: 5px;
                border-radius: 4px 4px 100px 100px;
              }
              span {
                width: 32px;
                height: 36px;
                border-radius: 50%;
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $white;
                background-repeat: no-repeat;
                background-size: 26px auto;
                background-position: center center;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .company {
              font-size: 14px;
              font-weight: 600;
              color: $black;
            }
            .show-rate {
              display: flex;
              align-items: center;
              font-weight: 600;
              span {
                @include mont-font();
              }
              .up {
                transform: rotate(90deg);
                display: block;
              }
              .down {
                transform: rotate(-90deg);
                display: block;
              }
            }
          }
        }
      }
    }
    .industry-hot-amount-warp {
      flex: 1;
      border-radius: 8px;
      background-color: $fff;
      padding: 15px;
      .pic-top-warp {
        display: flex;
        justify-content: space-between;
        .pic-title {
          font-size: 12px;
          color: $c87;
          margin-bottom: 10px;
          span {
            @include mont-font();
            font-size: 20px;
            color: $primary;
          }
        }
        .pic-right {
          color: $primary;
          cursor: pointer;
          display: flex;
          align-items: center;
          line-height: 36px;
        }
      }
      .amount-svg {
        height: 350px;
        width: 100%;
      }
    }
  }
}

.trend-content {
  .table {
    display: flex;
    justify-content: space-between;
    .right {
      width: 350px;
      margin-left: 10px;
      background-color: $fff;
      border-radius: 8px;
      padding: 10px;
      .year {
        p {
          line-height: 25px;
          font-size: 14px;
          color: $c55;
          &:nth-child(1) {
            line-height: auto;
            font-weight: 600;
          }
          .change_rate {
            @include mont-font();
            font-size: 14px;
          }
        }
      }
      .dispach-br {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        & > p {
          font-weight: 600;
        }
        .br {
          width: 100%;
          height: 1px;
          background-color: $ec;
        }
      }
    }
    .industry-trend-warp {
      flex: 1;
      border-radius: 8px;
      background-color: $fff;
      padding: 15px;
      height: 360px;
    }
  }
}
