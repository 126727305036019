@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-demand-pdf {
  .hot-role-pdf-warp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    .details {
      font-size: 12px;
      font-weight: 400;
      color: $c87;
      line-height: 12px;
      margin-top: 7px;
      margin-bottom: 5px;
    }
  }
  .hot-role-top-title {
    flex: 1;
    padding-top: 26px;
    .quantity-svg-warp {
      width: 100%;
      background: $fff;
      border-radius: 5px;
      padding: 5px 5px;
      .chart-check {
        font-size: 14px;
        font-weight: 600;
        color: $black;
        text-align: center;
        margin-left: 59px;
      }
      .chart-top {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
        .chart-left-title {
          width: 12px;
          color: $black;
          font-weight: 600;
          display: flex;
          align-items: center;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
          -o-transform: scale(0.8);
        }
        .quantity-chart {
          height: 343px;
          width: 100%;
        }
      }
      .chart-bot-num {
        margin-left: 50px;
        position: relative;
        width: calc(100% - 50px);
        height: 20px;
        span {
          @include mont-font();
          @include black-color(0.85);
          display: block;
          width: 20px;
          font-size: 12px;
          &:nth-child(1) {
            position: absolute;
            left: 0;
            top: 5px;
          }
          &:nth-child(2) {
            position: absolute;
            top: 5px;
            left: 20%;
            margin-left: -10px;
          }
          &:nth-child(3) {
            position: absolute;
            left: 40%;
            top: 5px;
            margin-left: -10px;
          }
          &:nth-child(4) {
            position: absolute;
            top: 5px;
            left: 60%;
            margin-left: -10px;
          }
          &:nth-child(5) {
            position: absolute;
            left: 80%;
            top: 5px;
            margin-left: -10px;
          }
          &:nth-child(6) {
            position: absolute;
            right: 0;
            top: 5px;
            margin-right: -5px;
          }
        }
      }
      .chart-bot {
        text-align: center;
        margin-top: 5px;
        font-weight: 600;
        color: $black;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        span {
          color: $da;
        }
      }
    }
    .hot-role-table {
      width: 100%;
      margin-top: 20px;
      .title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $ccc;
        margin-bottom: 10px;
        padding-bottom: 1px;
        p {
          width: 10.1%;
          font-size: 12px;
          background-color: $primary;
          height: 40px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          &:nth-child(1) {
            width: 15.5%;
          }
          b {
            display: flex;
            width: 142.85%;
            transform: scale(0.7);
            transform-origin: left top;
            -webkit-transform: scale(0.7);
            -moz-transform: scale(0.7);
            -ms-transform: scale(0.7);
            -o-transform: scale(0.7);
            justify-content: center;
            align-items: center;
            height: 57px;
            text-align: center;
            color: $white;
            font-weight: 600;
          }
        }
      }
      .table-content {
        p {
          display: flex;
          justify-content: space-between;
          span {
            @include mont-font;
            display: block;
            width: 10.1%;
            text-align: center;
            font-size: 12px;
            color: #181c25;
            font-weight: 600;
            height: 21px;
            line-height: 21px;
            margin-bottom: 1px;
            background-color: $white;
            &:nth-child(1) {
              color: $white;
              background-color: $c999;
              width: 15.5%;
            }
          }
          &:nth-child(1) {
            span {
              border-radius: 4px 4px 0 0;
            }
          }
          &:last-child {
            span {
              border-radius: 0 0 4px 4px;
            }
          }
        }
      }
    }
  }
}
