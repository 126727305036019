@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-demand-pdf {
  .city-pdf-warp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .city-top-title {
    flex: 1;
    padding-top: 26px;
    .details {
      font-size: 12px;
      font-weight: 400;
      color: $c87;
      line-height: 12px;
      margin-top: 7px;
      margin-bottom: 10px;
    }
    .detail {
      @include white-bg(1);
      @include black-color(0.85);
      font-size: 12px;
      padding: 6px 9px 0;
      // margin-top: 10px;
      border-radius: 5px 5px 0 0;
      span {
        @include black-color(0.85);
        margin-right: 10px;
        font-size: 12px;
      }
      i {
        @include mont-font();
        color: $primary;
        font-size: 16px;
      }
    }

    .city-svg-warp {
      width: 515px;
      height: 300px;
      background: $fff;
      border-radius: 5px;
      .city-svg {
        height: 300px;
        width: 100%;
        overflow-x: auto;
      }
    }
    .city-table-warp {
      margin-top: 20px;
      .table-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $ccc;
        margin-bottom: 10px;
        padding-bottom: 1px;
        p {
          width: 27.2%;
          color: $fff;
          height: 21px;
          border-radius: 4px;
          text-align: center;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 21px;
          &:nth-child(1) {
            width: 15.9%;
          }
        }
      }
      .table-content {
        p {
          display: flex;
          justify-content: space-between;
          span {
            @include mont-font;
            display: block;
            width: 27.2%;
            text-align: center;
            font-size: 12px;
            color: #181c25;
            font-weight: 600;
            height: 21px;
            line-height: 21px;
            margin-bottom: 1px;
            background-color: $white;
            &:nth-child(1) {
              color: $white;
              background-color: $c999;
              width: 15.9%;
            }
          }
          &:nth-child(1) {
            span {
              border-radius: 4px 4px 0 0;
            }
          }
          &:last-child {
            span {
              border-radius: 0 0 4px 4px;
            }
          }
        }
      }
    }
    .say-Details {
      width: 515px;
      background: $fff;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 20px;
      p {
        font-size: 12px;
        color: $c55;
        margin-top: 5px;
        &:nth-child(1) {
          font-weight: 600;
        }
      }
    }
  }
}
