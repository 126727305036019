@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.report-modal {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  width: 650px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);

  .one-050 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 26px 20px 20px 20px;

    .one-051 {
      display: inline-flex;
      flex-direction: column;

      b {
        padding-top: 4px;
        font-size: 16px;
        font-weight: 500;
        border-top: 2px solid $secondary;
        color: $c333;
      }
    }
    .span-006 {
      margin-top: 20px;
      font-size: 14px;
      color: $c333;
    }
    .span-007 {
      font-size: 14px;
      color: $primary;
    }
  }
  .one-055 {
    display: inline-flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid $eee;
  }

  .one-052 {
    display: inline-flex;
    flex-direction: column;
  }
}
