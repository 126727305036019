@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.commonHead {
  display: flex;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  border: 1px solid $ec;
  line-height: 36px;
  background-color: $f7;
  margin-top: 20px;
  b {
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid $ec;
    &:last-child {
      border-right: 0;
    }
  }
}

.commonTitle {
  @include mont-font();
  font-size: 12px;
  font-weight: 600;
  color: $black;
  line-height: 30px;
  padding-left: 20px;
  border: 1px solid $ec;
  border-top: 0;
  span {
    color: $c87;
  }
}

.commonEdit {
  display: flex;
  justify-content: space-between;
  border: 1px solid $ec;
  line-height: 36px;
  border-top: 0;
  > * {
    flex: 1 1 0;
  }
  input {
    @include mont-font();
    // flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid $ec;
    line-height: 36px;
    padding: 0 10px;
    color: $primary;
    text-align: center;
    :last-child {
      border-right: 0;
    }
  }
  p {
    @include mont-font();
    // flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid $ec;
    line-height: 36px;
    color: $black;
    font-weight: 600;
    font-size: 13px;
    background-color: $f7;
    text-align: center;
  }
}
.prompt {
  font-size: 12px;
  font-weight: 400;
  color: $error;
  margin-top: 20px;
}
.explanatory {
  font-size: 12px;
  font-weight: 400;
  color: $c87;
  margin-top: 20px;
}
.commonShow {
  display: flex;
  justify-content: space-between;
  border: 1px solid $ec;
  line-height: 36px;
  border-top: 0;
  b {
    @include mont-font();
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid $ec;
    line-height: 36px;
    padding: 0 10px;
    color: $black;
    font-weight: 600;
    text-align: center;
    &:last-child {
      border-right: 0;
    }
    &:first-child {
      background-color: $f7;
    }
  }
}

.btnGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.numProgress {
  height: 36px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 10px;
  margin-right: 20px;
  .num {
    text-align: right;
    span {
      color: $secondary;
    }
  }
}

.allQuestion {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .allContent {
    height: 350px;
    overflow-y: auto;
    padding-right: 5px;
    padding-bottom: 20px;
    overscroll-behavior:contain;
    .oneEdit {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-top: 20px;
      span {
        color: $c55;
        margin-right: 10px;
        display: block;
        width: 60px;
        font-weight: 600;
      }
      input {
        border: 1px solid $ec;
        border-radius: 5px;
        flex: 1;
        padding: 0 10px;
        line-height: 34px;
        font-weight: 600;
        color: $primary;
      }
    }
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      span {
        color: $c87;
      }
    }
    h3 {
      font-weight: 600;
      color: $black;
      margin-top: 20px;
      font-size: 14px;
      span {
        color: $c87;
      }
    }
    h4 {
      font-weight: 600;
      color: $black;
      margin-top: 20px;
      font-size: 14px;
    }
    .checkList {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      span {
        color: $c55;
        margin-left: 5px;
      }
      .active {
        color: $primary;
      }
      > div {
        flex: 1 !important;
        border: 1px solid $ec;
        margin-left: 10px;
        border-radius: 5px;
        padding: 0 10px;
      }
    }
    .items {
      margin-top: 20px;
      .proActive {
        color: $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        font-size: 14px;
      }
      .process {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        color: $c55;
        font-size: 14px;
      }
      label {
        @include mont-font();
        font-weight: 600;
      }
      b {
        font-weight: 600;
      }
    }
  }
}
