// 主色
$primary: #343f99;
$primaryDark: #2e3889;
$secondary: #f8ab42;
$secondaryDark: #de993b;

// 警告色
$success: #00c389;
$successDark: #00af7b;
$warning: $secondary;
$warningDark: $secondaryDark;
$error: #e02020;
$errorDark: #ca1c1c;
$orange: #fa6401;
$d15: #d15300;

// 图表2色
$t2One: $primary;
$t2OneDark: $primaryDark;
$t2Two: $secondary;
$t2TwoDark: $secondaryDark;

// 图表3色
$t3One: $primaryDark;
$t3OneDark: $primaryDark;
$t3Two: #86d4e0;
$t3TwoDark: #78bec9;
$t3Three: $secondary;
$t3ThreeDark: $secondaryDark;

// 图表7色
$t7One: $primary;
$t7OneDark: $primaryDark;
$t7Two: $t3Two;
$t7TwoDark: $t3TwoDark;
$t7Three: $secondary;
$t7ThreeDark: $secondaryDark;
$t7Four: #fa6400;
$t7FourDark: #e05900;
$t7Five: #00c389;
$t7FiveDark: #00af7b;
$t7Six: #44d7b6;
$t7SixDark: #3dc1a3;
$t7Seven: #ccc;
$t7SevenDark: #b7b7b7;

// other
$bodyBg: #f4f4f4;
$darkBg: #38415e;
$fff: #fff;
$white: #fff;
$C000: #000;
$black: #000;
$C19: #191919;
$ccc: #ccc;
$c333: #333;
$c666: #666;
$c999: #999;
$f4: #4284f4;
$f1: #f1f1f1;
$fa: #fafafa;
$cf: #cfcfcf;
$c87: #878787;
$c55: #485259;
$c34: #34993e;
$e2: #e24949;
$ec: #ececec;
$f7: #f7f8fb;
$c80: #800080;
$d9: #d9d9d9;
$eee: #eee;
$da: #9da2b0;
$p63: #263081;
$d4: #86d4e0;
$b8: #297b88;
$b7: #b77823;
$a6c: #019a6c;
$d8: #d8d8d8;
$e6: #E6E6E6;
$f6: #F6F6F6;

$dark: #4b536d;
$f00: #f0f0f0;
$f17: #0f1724;
$f3f5: #f3f5f8;
$eff: #eff0f4;
$f3f4: #f3f3f4;
$fad9: #fad961;
$f76b: #f76b1c;
$a186e: #186eff;
$a1987: #1987fe;
$d6fa: #d6fa61;
$c389: #00c389;
$df9: #df9532;
$c53: #33353d;
$f2: #f2f2f2;
$c33: #333333;
