@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.vip-customer {
  max-width: 1890px;
  margin-top: 60px;
  padding-left: 20px;

  .slick-section {
    .slick-box {
      @include white-bg(1);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 600px;
      height: 200px;
      padding: 0 30px 0 20px;
      border-radius: 10px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

      .copy-11 {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 3;
      }
      .text-61 {
        @include black-color(1);
        display: inline-flex;
        width: 348px;
        z-index: 5;
        font-size: 12px;
        line-height: 2;
      }
      .right-60 {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .circle-60 {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: $f7;
        }
        .span-60 {
          @include black-color(1);
          margin-top: 14px;
          font-size: 12px;
          line-height: 24px;
          font-weight: 300;
        }
      }
    }
  }
}
