@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.HomeQuestion {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .homeContent {
    height: 350px;
    overflow-y: auto;
  }
  .btnGroup {
    display: flex;
    justify-content: flex-start;
  }
  .btnGroupOne {
    display: flex;
    justify-content: center;
  }
  .numProgress {
    height: 36px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 10px;
    margin-right: 20px;
    .num {
      text-align: right;
      span {
        color: $secondary;
      }
    }
  }
  .selectTitle {
    font-size: 16px;
    font-weight: 600;
    color: $black;
  }
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 36px;
    cursor: pointer;
    .city {
      width: 90px;
      color: $c87;
      font-size: 14px;
    }
    .select {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $ec;
      border-radius: 5px;
      padding: 0 0 0 10px;
      height: 36px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin-left: 15px;
          font-size: 14px;
          color: $primary;
          font-weight: 600
        }
      }
      span {
        margin-right: 13px
      }
    }
  }
}

.industryWarpModel {
  @include white-bg(1);
  width: 520px;
  height: 360px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -260px;
  margin-top: -180px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  .title {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    span {
      padding-top: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      border-top: 2px solid $secondary;
      color: $c333;
    }
  }
  .contentWarp {
    display: flex;
    flex: 1;
    overflow: hidden;

    .industryWarp {
      display: flex;
      height: 100%;
      justify-content: space-between;

      .leftWarp {
        display: inline-flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 8px 0;
        width: 148px;
      }
      .rightWarp {
        flex: 1;
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 8px 0;
        overflow-y: auto;

        p {
          display: inline-flex;
          flex-wrap: wrap;
        }
      }
      b {
        display: inline-block;
        width: 100px;
        height: 26px;
        background: $white;
        border-radius: 3px;
        border: 1px solid $secondary;
        line-height: 26px;
        margin-top: 8px;
        text-align: center;
        font-weight: 500;
        color: $secondary;
        cursor: pointer;
      }
    }
  }
  .footerWarp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    padding: 0 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
}
