@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-demand-pdf {
  .company-rank-pdf-warp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .company-rank-top-title {
    flex: 1;
    padding-top: 26px;
    position: relative;
    .tags {
      position: absolute;
      right: 24px;
      top: 103px;
      font-size: 12px;
      color: $black;
      font-weight: 500;
    }
    .company-rank-svg-warp {
      @include white-bg(1);
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 0 24px 40px 24px;
      border-radius: 5px;
      .items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .svg-left {
          @include dark-border(0.1);
          width: 66px;
          height: 66px;
          border-radius: 50%;
          position: relative;
          padding: 10px;
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
          background-color: $fff;
          b {
            display: block;
            width: 46px;
            height: 46px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
          span {
            @include mont-font();
            text-align: center;
            color: $error;
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 12px;
            font-weight: 500;
            transform: scale(0.8);
            width: 36px;
            background: $white;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
            border-radius: 7px;
            line-height: 14px;
            -webkit-transform: scale(0.8);
            -moz-transform: scale(0.8);
            -ms-transform: scale(0.8);
            -o-transform: scale(0.8);
          }
        }
        .svg-mid {
          flex: 1;
          margin: 0 10px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: 66px;
          p {
            &:nth-child(1) {
              font-size: 14px;
              color: $black;
              font-weight: 500;
            }
            &:nth-child(2) {
              @include mont-font();
              font-size: 12px;
              color: $secondary;
            }
            &:nth-child(3) {
              background-color: $f2;
              height: 10px;
              border-radius: 8px;
              position: relative;
              b {
                display: block;
                width: 50%;
                height: 10px;
                border-radius: 8px;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(270deg, $fad9 0%, $f76b 100%);
              }
            }
          }
        }
        .svg-right {
          height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          p {
            display: flex;
            justify-content: flex-start;
            @include mont-font();
            &:nth-child(1) {
              color: $black;
            }
            .up {
              transform: rotate(90deg);
              display: block;
            }
            .down {
              transform: rotate(-90deg);
              display: block;
            }
          }
        }
      }
    }
  }
}
