@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.add-report-warp {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: white;
  padding: 0 20px 20px;
  .add-report-content {
    .prompt {
      padding: 15px 20px;
      font-size: 16px;
      line-height: 22px;
      color: $c55;
      font-weight: 600;
      border-bottom: 1px solid $ec;
      display: flex;
      justify-content: space-between;
      span {
        cursor: pointer;
      }
    }
    .add-query {
      width: 400px;
      margin: 30px auto 15px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: $black;
      line-height: 30px;
    }
    .telephone {
      font-size: 12px;
      font-weight: 600;
      color: $c55;
      line-height: 17px;
      text-align: center;
    }
  }
  .bottom-action {
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin: 50px auto 0;
  }
}
