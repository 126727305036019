@import "./color.scss";
@import "./global.scss";
@import "./mixin.scss";

@media (max-width: 1600px) {
  .header-container {
    padding: 0 40px !important;
  }
  .content-all-middle {
    padding: 0 40px;
  }
  .knowledge-top {
    padding: 0 40px;
  }
  .user-pop_50 .user-pop_51 {
    padding-right: 40px !important;
  }
  .header-pop_100 .pop-1010 {
    padding-left: 65px;
  }
  .effect-content {
    padding-left: 40px;
    padding-right: 40px;
  }
  .index-container_10 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .header-container {
    padding: 0 80px !important;
  }
  .content-all-middle {
    padding: 0 80px;
  }
  .knowledge-top {
    padding: 0 40px;
  }
  .user-pop_50 .user-pop_51 {
    padding-right: 80px !important;
  }
  .header-pop_100 .pop-1010 {
    padding-left: 280px;
  }
  .tri-11 { left: 40px !important; }
  .tri-12 { left: 215px !important; }
  .tri-13 { left: 550px !important; }
  .effect-content {
    padding-left: 80px;
    padding-right: 80px;
  }
  .index-container_10 {
    padding-left: 80px;
    padding-right: 80px;
  }
}
