@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.vip-page {
  width: 100%;
  min-width: 1280px;
  display: flex;
  flex-direction: column;

  .vip-container {
    display: flex;
    flex-direction: column;

    .vip-banner {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 560px;
      background-image: url('https://file.smartsalary.cn/web-img%402.0/home/bg-price.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;

      .banner-10 {
        width: 100%;
        max-width: 1920px;
        display: flex;
        justify-content: space-between;
        padding: 249px 130px 0 130px;
      }

      .section-11 {
        display: inline-flex;
        flex-direction: column;

        .p-11 {
          @include white-color(1);
          font-size: 36px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 30px;
        }
        .p-12 {
          display: inline-flex;
          flex-direction: column;

          span {
            @include white-color(1);
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            margin-top: 20px;
          }
        }
      }

      .section-12 {
        display: flex;
        flex-direction: column;
        padding-top: 60px;

        .p-20 {
          @include white-color(1);
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          margin-top: 15px;
        }
      }
      .line-15051 {
        padding-top: 60px;
        color: $fff;
      }
    }

    .vip-meal {
      @include white-bg(1);
      display: flex;
      justify-content: center;

      .meal-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1920px;
        // padding: 0 20px 100px 20px;

        .meal-section {
          display: flex;
          flex-direction: column;
        }
        .meal-10 {
          padding-top: 80px;

          .line-91 {
            display: inline-flex;
            justify-content: center;
            width: 100%;
          }
          .line-901 {
            margin-bottom: 10px;
            font-size: 14px;
            padding-top: 6px;
          }
        }

        .meal-11 {
          padding-top: 100px;
        }

        .meal-12 {
          padding-top: 100px;
        }

        .meal-content {
          display: flex;
        }
      }
    }
  }
  .vip-container, .customer-50 {
    .meal-head {
      display: inline-flex;
      flex-direction: column;

      .title-101 {
        @include black-color(1);
        width: 100%;
        display: inline-flex;
        justify-content: center;
        font-size: 38px;
        font-weight: 600;
        line-height: 1;
      }
      .title-102 {
        font-size: 30px;
      }
      .p-102 {
        display: inline-flex;
        justify-content: center;
        width: 100%;
        margin-top: 18px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #878787;
      }
    }
  }

  .customer-50 {
    display: flex;
    justify-content: center;
    background-color: $f7;

    .customer-content {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 1280px;
      max-width: 1890px;
      padding: 80px 0 60px 0;

      .temp-10 {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 30px;
        background-color: $f7;
        z-index: 888;
      }
    }
  }
}
