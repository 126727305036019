@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-demand-warp {
  .human-huring-banner {
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, $a186e 0%, $a1987 100%);
    border-radius: 8px;
    margin-top: 20px;
    color: $white;
    font-size: 40px;
    text-align: center;
    line-height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    img {
      cursor: pointer;
    }
  }
}
