@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.vip-service {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  padding: 60px 0 100px 0;

  .service-container {
    display: flex;
    position: relative;

    .box1, .box2 {
      width: 600px;
      height: 420px;
      transform: skew(9deg);
      z-index: 5;
      border-radius: 20px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    }
    .box1 { background-color: $f7; }
    .box2 {
      @include white-bg(1);
      margin-left: 20px;
    }
    .box3 {
      width: 400px;
      height: 420px;
      position: absolute;
      left: -32px;
      z-index: 10;
      border-radius: 20px;
      background-color: $f7;
    }
    .box4 {
      @include white-bg(1);
      width: 400px;
      height: 420px;
      position: absolute;
      right: -32px;
      z-index: 10;
    }
    .section-01, .section-02 {
      position: absolute;
      height: 420px;
      width: 500px;
      display: flex;
      z-index: 35;
      flex-direction: column;
      justify-content: center;

      b {
        @include black-color(1);
        font-size: 28px;
        line-height: 1;
        font-weight: 600;
      }
      .one-31 {
        margin-top: 30px;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        color: $c87;
      }
    }
    .section-01 {
      left: 0;
      align-items: center;
      margin-left: 60px;

      b {
        margin-bottom: 20px;
      }
    }
    .section-02 {
      left: 680px;

      b {
        display: inline-flex;
        justify-content: center;
        padding-right: 120px;
        margin-bottom: 20px;
      }
      .line-05 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        padding-right: 30px;

        .span-05, .span-06 {
          font-size: 14px;
        }
        .span-05 { @include secondary-color(1); }
        .span-06 { @include black-color(1); }
        .span-07 { color: $c87; }
      }
    }
  }
}
